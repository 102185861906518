import { Link } from "gatsby"
import "./header.scss"
import React, { useState } from "react"
import ContainerMenu from "../../shared/container-menu/container-menu"
import logo from "../../images/logo-lepri.jpeg"
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)
  const [isVisible, setIsVisible] = useState(false)

  const toggleMenu = () => {
    setIsVisible(!isVisible)
  }

  return (
    <header>
      <div className="toolbar p-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
              <img
                alt="fratelli lepri arredamenti"
                className="img-fluid "
                src={logo}
              ></img>
            </div>
            <div className="col-6 menu-bar">
              <div className="bar-menu">
                {data.site.siteMetadata.menu.map(item => {
                  return (
                    <Link
                      activeStyle={{ color: "rgba(1, 136, 1, 0.644)" }}
                      to={`/${item.to}`}
                      className="link"
                      key={item.name}
                    >
                      {item.name}
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="toggle-menu col">
              <button onClick={toggleMenu} className="button-menu">
                <svg
                  width="3rem"
                  height="3rem"
                  viewBox="0 0 16 16"
                  className="bi bi-justify"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {
        <ContainerMenu
          className="toggle-menu"
          showMe={isVisible}
        ></ContainerMenu>
      }
    </header>
  )
}

export default Header
