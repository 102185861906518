import { Link } from "gatsby"
import React from "react"
import "./container-menu.scss"
import { useStaticQuery, graphql } from "gatsby"

const ContainerMenu = props => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)
  return props.showMe ? (
    <div className="container-menu">
      {data.site.siteMetadata.menu.map(item => {
        return (
          <Link
            activeStyle={{ color: "rgba(1, 136, 1, 0.644)" }}
            to={`/${item.to}`}
            key={item.name}
            className="item-menu"
          >
            {item.name}
          </Link>
        )
      })}
    </div>
  ) : null
}

export default ContainerMenu
