import React from "react"
import "./footer.scss"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          footer {
            description
            title
          }
          author
        }
      }
    }
  `)

  return (
    <div className="container  ">
      <div className=" row py-2 border-div">
        <div className="d-flex align-items-center justify-content-center col-xl-6 col-md-12">
          <span className="footer-label">
            {data.site.siteMetadata.footer.title}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-center col-xl-6 col-md-12">
          <span className="footer-label">
            {data.site.siteMetadata.footer.description}
            {data.site.siteMetadata.author}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer
